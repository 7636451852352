import { ReactNode, createContext, useState } from 'react';

export const PinAndListInteractionContext = createContext({} as ReturnType<typeof useValue>);

const useValue = () => {
  const [activeIndex, setActiveIndex] = useState<number | undefined>(undefined);
  const [hoveredIndex, setHoveredIndex] = useState<number | undefined>(undefined);

  return {
    activeIndex,
    setActiveIndex,
    hoveredIndex,
    setHoveredIndex,
  };
};

export const PinAndListInteractionProvider: React.FC<{ children: ReactNode }> = ({ children }) => {
  const value = useValue();

  return (
    <PinAndListInteractionContext.Provider value={value}>
      {children}
    </PinAndListInteractionContext.Provider>
  );
};
