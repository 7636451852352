import { PropsWithChildren, useMemo, useState, createContext } from 'react';
import { v4 as uuid } from 'uuid';

// types
import { DealerType, IEntry, ILocation } from '../../locator.types';

// utils
import { useSearchParamsState } from 'utils/hooks/use-search-params-state';
import { withDistance, sortByDistance, IWithDistance, sortByGeberit } from '../utils/pins-manager';
import { getDealerCategory } from '../../utils/dealer-helpers';
import { isDefined } from 'utils/ts-utilities';
import { arrayUnique } from 'utils/array-unique';

export const FilterContext = createContext({} as ReturnType<typeof useValue>);

export interface IEntryWithKey extends IEntry {
  key: string;
}

const FILTER_KEYS = ['pro_cat', 'pro_ser', 'cat', 'partner', 'country'];

function filterCategory(entries: IEntryWithKey[], category?: string) {
  if (!category || category === 'all') {
    return entries;
  }

  return entries.filter((entry) =>
    getDealerCategory(entry).includes(category.toLowerCase() as 'i' | 's'),
  );
}

function filterProductCategory(entries: IEntryWithKey[], productCategory?: string) {
  if (!productCategory) {
    return entries;
  }

  return entries.filter((entry) =>
    entry.products.some((product) => product.category === productCategory),
  );
}

function filterProductSerie(entries: IEntryWithKey[], productSerie?: string) {
  if (!productSerie) {
    return entries;
  }

  return entries.filter((entry) =>
    entry.products.some((product) => product.series.includes(productSerie)),
  );
}

const useValue = (entries: IEntry[], sortStructure: string[], location?: ILocation) => {
  const [state, setSearchParamsState] = useSearchParamsState(FILTER_KEYS);
  const [filters, setFilters] = useState<Record<string, string | undefined>>({});
  const [showFilter, setShowFilter] = useState(false);

  function saveAppliedFilters() {
    setFilters(state);
  }

  function restoreSavedFilters() {
    setSearchParamsState(filters);
  }

  function resetFilters() {
    setSearchParamsState(FILTER_KEYS.reduce((acc, key) => ({ ...acc, [key]: undefined }), {}));
  }

  const activeCategories = useMemo(() => {
    return Array.from(
      entries.reduce((acc, entry) => {
        const result = getDealerCategory(entry);
        if (Array.isArray(result)) {
          result.forEach((cat) => acc.add(cat));
        } else {
          acc.add(result);
        }

        return acc;
      }, new Set<Lowercase<DealerType>>()),
    );
  }, [entries]);

  const entriesWithKey = useMemo(() => {
    return entries.map((loc) => ({
      ...loc,
      key: uuid(),
    }));
  }, [entries]);

  const filteredCategories = useMemo(() => {
    return filterCategory(entriesWithKey, state.cat);
  }, [state.cat, entriesWithKey]);

  const filteredProductCategories = useMemo(() => {
    const productCategory = state.pro_cat === 'all' ? undefined : state.pro_cat;

    return filterProductCategory(filteredCategories, productCategory);
  }, [state.pro_cat, filteredCategories]);

  const filteredSeries = useMemo(() => {
    const productSerie = state.pro_ser === 'all' ? undefined : state.pro_ser;
    let items = filterProductSerie(filteredProductCategories, productSerie);
    if (location?.coordinates) {
      items = items.map(withDistance(location.coordinates)).sort(sortByDistance);
    }

    return (items as IWithDistance<IEntryWithKey>[]).slice().sort(sortByGeberit(!!state.partner));
  }, [state.pro_ser, filteredProductCategories, location, state.partner]);

  const filteredEntries = filteredSeries.filter(
    (entry) => !state.country || entry.country === state.country,
  );

  const countries = arrayUnique(entries.map((entry) => entry?.country)).filter(isDefined);

  return {
    countries,
    filteredEntries,
    entries,
    activeCategories,
    filters: state,
    saveAppliedFilters,
    restoreSavedFilters,
    showFilter,
    setShowFilter,
    resetFilters,
    sortStructure,
  };
};

export const FilterContextProvider = ({
  entries,
  location,
  children,
  sortStructure,
}: PropsWithChildren<{ entries: IEntry[]; location?: ILocation; sortStructure: string[] }>) => {
  return (
    <FilterContext.Provider value={useValue(entries, sortStructure, location)}>
      {children}
    </FilterContext.Provider>
  );
};
