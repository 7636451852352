import React from 'react';
import { Dropdown } from '@geberit/gdds';

// styles
import { StyledContainer } from './secondary-filter-view.styles';

// utils
import { useIsDesktop } from 'components/App/SizeProvider';
import { IFilterOption, ISecondaryFilter, useSecondaryFilter } from './secondary-filter-view.hook';

export function SecondaryFilterView() {
  const [{ label, items, selected }, onChange] = useSecondaryFilter();
  const isDesktop = useIsDesktop({ gdds: true });
  const labelKey = isDesktop ? 'placeholder' : 'label';

  const props = (key: ISecondaryFilter) => ({
    placeholder: label.all,
    // on !desktop we have a placeholder and a label - on desktop the placeholer will
    // be the label
    [labelKey]: label[key],
    name: key,
    items: items[key],
    values: selected,
    outlined: !isDesktop,
    forceCustom: true,
    onChange: (option: IFilterOption) => {
      onChange(key, option);
    },
  });

  return (
    <StyledContainer>
      <Dropdown {...props('categories')} />
      <Dropdown {...props('series')} />
      {props('countries').items && <Dropdown {...props('countries')} />}
    </StyledContainer>
  );
}
