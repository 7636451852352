import { useCallback } from 'react';
import { useSelector } from 'react-redux';
import { useRouter } from 'next/router';

// utils
import { generateDealerUrl } from '../utils/generate-dealer-url';
import { useLocatorConfiguration } from '../utils/use-locator-configuration';
import { currentPageSelector } from 'utils/selectors/pageSelectors';

export function useGoToDealerPage() {
  const locatorConfig = useLocatorConfiguration();
  const page = useSelector(currentPageSelector);
  const locale = page.metaData?.lang?.slice(0, 2).toLowerCase();
  const router = useRouter();

  return useCallback(
    (dealer: { name: string; city: string }) => {
      let dealerPageUrl = `${locatorConfig.url}/${generateDealerUrl(dealer, locale)}`;
      dealerPageUrl = dealerPageUrl.replace(/\/\//, '/');

      router.push(
        `${dealerPageUrl}${
          window.location.search ? `?_search=${encodeURIComponent(window.location.search)}` : ''
        }`,
        dealerPageUrl,
      );
    },
    [locatorConfig.url, locale, router],
  );
}
