import { PropsWithChildren, createContext, useEffect, useMemo, useState } from 'react';

// types
import type { IEntry, ILocation } from '../locator.types';

// utils
import { usePermissionsStatus } from './utils/use-permission-status';
import { geocode, geodecode, getCoordinates } from 'utils/get-coordinates';
import { useSearchParamsState } from 'utils/hooks/use-search-params-state';
import { useIsoCountry } from 'scenes/MetaData';
import { isDefined } from 'utils/ts-utilities';
import { arrayUnique } from 'utils/array-unique';

const useValue = ({
  locatorUrl,
  entries,
}: {
  locatorUrl?: string;
  entries?: IEntry<undefined>[];
}) => {
  const [geoLocated, setGeoLocated] = useState(false);
  const [geoLocationPending, setGeoLocationPending] = useState(false);
  const [error, setError] = useState(false);
  const [searchParamsState, setSearchParamsState] = useSearchParamsState([
    'lat',
    'lng',
    'addr',
    'country',
  ]);
  const isPermitted = usePermissionsStatus();
  const country = useIsoCountry().toLowerCase();
  const countries = entries
    ? arrayUnique(entries?.map((entry) => entry?.country?.toLowerCase())).filter(isDefined)
    : undefined;

  const location = useMemo((): ILocation | undefined => {
    if (!searchParamsState?.lat || !searchParamsState?.lng) {
      return undefined;
    }

    return {
      coordinates: {
        lat: Number(searchParamsState.lat),
        lng: Number(searchParamsState.lng),
      },
      address: searchParamsState.addr,
    };
  }, [searchParamsState]);

  async function locate() {
    try {
      setError(false);
      setGeoLocationPending(true);

      const coordinates = await getCoordinates();
      if (coordinates) {
        const location = await geodecode({
          lat: Number(coordinates.lat),
          lng: Number(coordinates.lng),
        });
        setGeoLocated(true);
        setSearchParamsState(
          {
            lat: String(coordinates.lat),
            lng: String(coordinates.lng),
            addr: location?.address,
          },
          locatorUrl,
        );
      }
    } catch (error) {
      setError(true);
    } finally {
      setGeoLocationPending(false);
    }
  }

  async function findLocation(val: string) {
    const location = await geocode(val, country);
    if (location) {
      setGeoLocated(false);
      const addressComponent = location.address_components.find(
        (address) => address.types[0] === 'country',
      );
      let country: string | undefined = undefined;
      if (searchParamsState.country) {
        if (countries && addressComponent?.short_name) {
          if (countries.includes(addressComponent.short_name.toLowerCase())) {
            country = addressComponent.short_name;
          }
        }
      }

      setSearchParamsState(
        {
          lat: String(location.coordinates.lat),
          lng: String(location.coordinates.lng),
          addr: location.address,
          country,
        },
        locatorUrl,
      );
    }
  }

  function resetError() {
    setError(false);
  }

  useEffect(() => {
    if (isPermitted) {
      resetError();
    }
  }, [isPermitted]);

  return {
    location,
    error,
    geoLocated,
    geoLocationPending,
    locate,
    findLocation,
    resetError,
  };
};

export const SearchContext = createContext({} as ReturnType<typeof useValue>);

export const SearchContextProvider = ({
  children,
  locatorUrl,
  entries,
}: PropsWithChildren<{ locatorUrl?: string; entries?: IEntry<undefined>[] }>) => {
  return (
    <SearchContext.Provider value={useValue({ locatorUrl, entries })}>
      {children}
    </SearchContext.Provider>
  );
};
