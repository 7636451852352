import { useContext, useEffect, createRef } from 'react';
import { Typo } from '@geberit/gdds';

// styles
import { ListBody, ListHeader, ScrollContainer, StyledList, StyledListHeader } from './list.styles';

// types
import type { ILocation } from '../../locator.types';

// components
import { ListTile } from './list-tile';
import { PartnerSwitch } from './partner-switch';

// utils
import { IWithDistance } from '../utils/pins-manager';
import { useLocatorConfiguration } from '../../utils/use-locator-configuration';
import { PinAndListInteractionContext } from '../providers/pin-and-list-interaction-provider';
import { IEntryWithKey } from '../providers/filter-provider';
import { useIsDesktop } from 'components/App/SizeProvider';
import { decodingContent } from 'utils/decodingContent';

const MAX_ITEMS_TO_DISPLAY = 50;

interface ListProps {
  items: IWithDistance<IEntryWithKey>[];
  location: ILocation;
  onDealerSelected: (dealerLocation: IWithDistance<IEntryWithKey>) => void;
}

export function List({ items, location, onDealerSelected }: Readonly<ListProps>) {
  const isDesktop = useIsDesktop({ gdds: true });
  const locatorConfiguration = useLocatorConfiguration();
  const { activeIndex } = useContext(PinAndListInteractionContext);
  const scrollContainerRef = createRef<HTMLDivElement>();

  useEffect(() => {
    if (isDesktop && activeIndex !== undefined) {
      const activeListEntry = document.getElementById(`dealer-index-${activeIndex}`);
      const topPos = activeListEntry?.offsetTop ?? 0;
      scrollContainerRef.current?.scroll({ top: topPos - 40, behavior: 'smooth' });
    }
  }, [activeIndex, isDesktop, scrollContainerRef]);

  return (
    <StyledList>
      <ListHeader>
        <StyledListHeader variant="p2">
          <span className="count">
            {items.length > MAX_ITEMS_TO_DISPLAY ? `${MAX_ITEMS_TO_DISPLAY}+` : items.length}
          </span>
          &nbsp;
          {decodingContent(
            isDesktop ? locatorConfiguration.map?.results : locatorConfiguration.map?.resultsNear,
          )}
        </StyledListHeader>
        {isDesktop && <PartnerSwitch />}
      </ListHeader>
      <ScrollContainer ref={scrollContainerRef}>
        <ListBody>
          {items.slice(0, MAX_ITEMS_TO_DISPLAY).map((item) => (
            <ListTile
              onSelected={() => onDealerSelected(item)}
              location={location}
              {...item}
              key={item.key}
            />
          ))}
        </ListBody>
        {items.length > MAX_ITEMS_TO_DISPLAY && (
          <Typo className="search-note" variant="p1">
            {locatorConfiguration.search?.searchNote}
          </Typo>
        )}
      </ScrollContainer>
    </StyledList>
  );
}
