import { Typo } from '@geberit/gdds';

// types
import type { IEntry, ILocation } from '../locator.types';
import type { IWithDistance } from './utils/pins-manager';

// utils
import { useCurrentLanguage } from 'utils/hooks/useCurrentLanguage';
import { useDuration } from './hooks/use-duration';

interface DistanceAndDurationProps {
  entry?: Omit<IWithDistance<IEntry>, 'category' | 'products' | 'partnertype'>;
  location: ILocation;
  showDuration: boolean;
}

export function DistanceAndDuration({
  entry = { name: '', address: '', city: '', zip: '', distance: 0, lat: 0, lng: 0 },
  location,
  showDuration,
}: Readonly<DistanceAndDurationProps>) {
  const currentLanguage = useCurrentLanguage();
  const { name, address, zip, city, lng, lat, distance } = entry;
  const lang = currentLanguage?.slice(0, 2)?.toLowerCase() || 'en';
  const destination = `${name} ${address} ${zip} ${city}`;
  const duration = useDuration(
    location,
    { address: destination, coordinates: { lng, lat } },
    showDuration,
    false,
  );
  const { hours, minutes } = toHoursAndMinutes(duration ?? 0);
  const locales = [lang, 'en']; // 'en' as fallback if lang is not supported

  return (
    <div className="distance-duration">
      <Typo variant="p2">
        {new Intl.NumberFormat(locales, {
          style: 'unit',
          unit: 'kilometer',
          maximumFractionDigits: 2,
        }).format(distance / 1000)}
      </Typo>
      {Boolean(hours || minutes) && (
        <Typo variant="p2">
          (
          {Boolean(hours) &&
            `${new Intl.NumberFormat(locales, {
              style: 'unit',
              unit: 'hour',
            }).format(hours)} `}
          {Boolean(minutes) &&
            new Intl.NumberFormat(locales, {
              style: 'unit',
              unit: 'minute',
            }).format(minutes)}
          )
        </Typo>
      )}
    </div>
  );
}

function toHoursAndMinutes(totalSeconds: number) {
  const totalMinutes = Math.round(totalSeconds / 60);
  const hours = Math.floor(totalMinutes / 60);
  const minutes = totalMinutes % 60;

  return { hours, minutes };
}
